<template>
  <ul
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <a
      class="sidebar-brand d-flex align-items-center justify-content-center"
      href="/dashboard"
      style="background-color: white"
    >
      <!-- <div class="sidebar-brand-icon rotate-n-15">
        <i class="fas fa-laugh-wink"></i>
      </div> -->
      <img
        src="../../assets/favicon.png"
        style="height: 35px; width: 160px"
        alt="LOGO"
      />
      <!-- <span class="ms-1 font-weight-bold">Innovative Toll</span> -->
      <!-- <div class="sidebar-brand-text mx-3">Innovative Toll</div> -->
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <!-- <li class="nav-item pl-1">
 
      <router-link class="nav-link" to="/dashboard"
        ><i class="fas fa-fw fa-tachometer-alt"></i>
        <span>DASHBOARD</span></router-link
      >
    </li> -->

    <!-- Divider -->
    <hr class="sidebar-divider" />
    <!-- Heading -->
    <!-- Nav Item - Pages Collapse Menu -->

    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapse"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-money-bill"></i>
        <span>PAYMENT</span>
      </a>
      <div
        id="collapse"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Payment Overview</h6>
          <router-link class="collapse-item" to="/my-payments"
            >My Payment Requests
          </router-link>

          <span
            v-if="
              AdminRole === 'Front Office' ||
              AdminRole === 'Supervisor' ||
              AdminRole === 'Admin' ||
              AdminId === '46'
            "
          >
            <h6 class="collapse-header">All Payment Requests</h6>
            <router-link class="collapse-item" to="/all-payments">
              All Payment Requests
            </router-link>
          </span>
        </div>
      </div>
    </li>

    <!-- <li class="nav-item pl-1">
      <router-link class="nav-link" to="/daily-report"><i class="fas fa-file-alt"></i>
        <span>DAILY REPORT</span></router-link>
    </li> -->
    <hr class="sidebar-divider d-none d-md-block" />
  </ul>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      role: "",
      username: "",
      AdminId: "",
    };
  },
  methods: {
    // goBack() {
    //   // Navigate back in the browser history
    //   window.history.back();
    //   window.location.reload();
    // },
  },
  created() {
    this.AdminId = JSON.parse(localStorage.getItem("user")).id;
    // this.username = this.currentUser.username;
    // this.role = localStorage.getItem("role");
    this.AdminRole = localStorage.getItem("role");
  },
};
</script>
